<script setup lang="ts">
/**
 * @file It's named "contained button" following Material UI's terminology: https://material.io/components/buttons .
 *
 *
 * This component applies three types of styles:
 *
 * 1. Shape/size
 * 2. Colors
 * 3. Text truncation
 *
 *
 * To make customization easy, it's built so that you can (re)use each group of styles independently,
 * i.e. each type of style is optional. Notice that if you set `:color-scheme="null"`, `:size-preset="null"`
 * and use the default slot, it reduces to `OzBaseButton`.
 *
 */
import OzBaseButton, { OzBaseButtonHrefMode } from '@@/library/v4/components/OzBaseButton.vue'
import { VueCssClass } from '@@/types'

withDefaults(
  defineProps<{
    darkMode?: boolean | 'auto'
    disabled?: boolean
    href?: string
    hrefMode?: OzBaseButtonHrefMode
    colorScheme?: OzContainedButtonColorScheme
    /**
     * Applies preset sizes/shapes.
     * They are named with pixel values for developer ergonomics -- those are the values you see in the mockups.
     * Technically, the pixel value could vary depending on the size of `rem`.
     */
    sizePreset?: OzContainedButtonSizePreset
    text?: string
    /**
     * Title. If not defined, text will be used instead.
     */
    title?: string
    /**
     * Adds extra padding to center the text if there's an icon.
     */
    centerText?: boolean
    /**
     * Defines whether the button text should be truncated or not.
     * Use case:
     *
     * 1. `true` - truncate the button text if it exceeds the button's width
     * 2. `false` - do not truncate the button text, allowing it to stretch the button's height
     */
    shouldTruncateText?: boolean
    /**
     * Defines whether the button's height should be fixed or adjusted by its content.
     */
    autoHeight?: boolean
    /**
     * Tailwind uses box shadows for focus rings.
     * Didn't find a straightforward way to implement truly transparent offset color.
     * If the gap color is too different from the backdrop, customize it here.
     */
    ringOffsetColorClasses?: VueCssClass
    textAlign?: 'start' | 'center' | 'end'
  }>(),
  {
    darkMode: 'auto',
    disabled: false,
    href: undefined,
    hrefMode: OzBaseButtonHrefMode.Default,
    colorScheme: OzContainedButtonColorScheme.Primary,
    sizePreset: OzContainedButtonSizePreset.H40px,
    text: undefined,
    title: undefined,
    centerText: false,
    shouldTruncateText: true,
    autoHeight: false,
    ringOffsetColorClasses: undefined,
    textAlign: 'center',
  },
)

defineEmits<{
  (name: 'click', event: MouseEvent): void
  (name: 'mouseenter', event: MouseEvent): void
  (name: 'mouseleave', event: MouseEvent): void
  (name: 'mousedown', event: MouseEvent): void
  (name: 'focus', event: FocusEvent): void
  (name: 'blur', event: FocusEvent): void
  (name: 'pointerdown', event: PointerEvent): void
}>()
</script>

<script lang="ts">
export enum OzContainedButtonColorScheme {
  Bare = 'Bare',
  Primary = 'Primary',
  PrimaryPulsing = 'PrimaryPulsing',
  Secondary = 'Secondary',
  SecondaryActive = 'SecondaryActive',
  SecondaryContrast = 'SecondaryContrast',
  SecondaryClear = 'SecondaryClear',
  SecondaryClearDark = 'SecondaryClearDark',
  SecondaryClearText = 'SecondaryClearText',
  SecondaryDestructive = 'SecondaryDestructive',
  SecondaryClearActive = 'SecondaryClearActive',
  SecondaryLightHover = 'SecondaryLightHover',
  Alternative = 'Alternative',
  AlternativeSecondary = 'AlternativeSecondary', // Alternative2 in Figma
  AlternativeHeavy = 'AlternativeHeavy',
  Green = 'Green',
  Orange = 'Orange',
  Destructive = 'Destructive',
  DestructiveClear = 'DestructiveClear',
  Active = 'Active',
  Transparent = 'Transparent',
  Grey = 'Grey',
  Purple = 'Purple',
  Blue = 'Blue',
  BlueClear = 'BlueClear',
  GreenClear = 'GreenClear',
}

export enum OzContainedButtonSizePreset {
  Bare = 'Bare',
  H80px = 'H80px',
  H64px = 'H64px',
  H56px = 'H56px',
  H40px = 'H40px',
  H32px = 'H32px',
  H24px = 'H24px',
  H32pxW56px = 'H32pxW56px',
  H40W124px = 'H40W124px',
}

export { OzBaseButtonHrefMode as OzContainedButtonHrefMode } from '@@/library/v4/components/OzBaseButton.vue'
export default {}
</script>

<template>
  <OzBaseButton
    :disabled="disabled"
    :href="href"
    :href-mode="hrefMode"
    :title="title || text"
    :class="[
      /**
       * SIZE/SHAPE PRESETS
       */

      // Common styles
      sizePreset && [
        // Remove the default focus ring since we're rounding the corners
        'focus:outline-none',

        // Centralize button content
        'flex',
        'items-center',
        'justify-center',

        // Text-alignment
        textAlign === 'start' && 'text-start',
        textAlign === 'center' && 'text-center',
        textAlign === 'end' && 'text-end',
      ],

      // Vertical padding presets
      sizePreset && {
        // Reset user agent styles
        // Don't set px-0 here so we won't have specificity issues
        'py-0': sizePreset !== OzContainedButtonSizePreset.H80px,
        'py-4': sizePreset === OzContainedButtonSizePreset.H80px,
      },

      // Presets
      sizePreset === OzContainedButtonSizePreset.H80px && [
        autoHeight ? 'min-h-20' : 'h-20',
        'box-border',
        'px-7',
        'rounded-3xl',
        'text-heading-4',
        'font-semibold',
        'focus-visible:ring',

        'ring-offset-1',
        // no icon design provided at the time of creating this OzContainedButtonSizePreset. to be added when there is a use case
      ],
      sizePreset === OzContainedButtonSizePreset.H64px && [
        autoHeight ? 'min-h-16' : 'h-16',
        'rounded-[26px]',
        'text-heading-2-large',
        'font-semibold',
        'focus-visible:ring-[3px]',

        // No ring offset when only icon. Offset is present otherwise.
        !$slots.icon && [
          // Needs to be on a new line for the JIT to work -_-
          'ring-offset-[3px]',
        ],

        // Expect at most one of the icon slots to be filled.
        $slots.icon && ['w-16'],
        $slots.leftIcon && !centerText && ['pe-5', 'ps-4', 'gap-2'],
        $slots.leftIcon && centerText && ['pe-11', 'ps-4', 'gap-2'], // pe-11 = ps-4 + 20px + gap-2
        $slots.rightIcon && !centerText && ['pe-2', 'ps-5', 'gap-1'],
        $slots.rightIcon && centerText && ['pe-2', 'ps-8', 'gap-1'], // ps-8 = pe-2 + 20px + gap-1
        !$slots.icon && !$slots.leftIcon && !$slots.rightIcon && ['px-8'],
      ],
      sizePreset === OzContainedButtonSizePreset.H56px && [
        autoHeight ? 'min-h-14' : 'h-14',
        'rounded-[22px]',
        'text-heading-2',
        'font-semibold',
        'focus-visible:ring-[3px]',

        // No ring offset when only icon. Offset is present otherwise.
        !$slots.icon && [
          // Needs to be on a new line for the JIT to work -_-
          'ring-offset-2',
        ],

        // Expect at most one of the icon slots to be filled.
        $slots.icon && ['w-14'],
        $slots.leftIcon && !centerText && ['pe-5', 'ps-4', 'gap-2'],
        $slots.leftIcon && centerText && ['pe-11', 'ps-4', 'gap-2'], // pe-11 = ps-4 + 20px + gap-2
        $slots.rightIcon && !centerText && ['pe-2', 'ps-5', 'gap-1'],
        $slots.rightIcon && centerText && ['pe-2', 'ps-8', 'gap-1'], // ps-8 = pe-2 + 20px + gap-1
        !$slots.icon && !$slots.leftIcon && !$slots.rightIcon && ['px-7'],
      ],
      sizePreset === OzContainedButtonSizePreset.H40px && [
        autoHeight ? 'min-h-10' : 'h-10',
        'rounded-2xl',
        'text-heading-4',
        'font-semibold',
        'focus-visible:ring-[2.5px]',

        // No ring offset when only icon. Offset is present otherwise.
        !$slots.icon && [
          // Needs to be on a new line for the JIT to work -_-
          'ring-offset-[1.5px]',
        ],

        // Expect at most one of the icon slots to be filled.
        $slots.icon && ['w-10'],
        $slots.leftIcon && !centerText && ['pe-5', 'ps-4', 'gap-2'],
        $slots.leftIcon && centerText && ['pe-11', 'ps-4', 'gap-2'], // pe-11 = ps-4 + 20px + gap-2
        $slots.rightIcon && !centerText && ['pe-2', 'ps-5', 'gap-1'],
        $slots.rightIcon && centerText && ['pe-2', 'ps-8', 'gap-1'], // ps-8 = pe-2 + 20px + gap-1
        !$slots.icon && !$slots.leftIcon && !$slots.rightIcon && ['px-5'],
      ],
      sizePreset === OzContainedButtonSizePreset.H32px && [
        autoHeight ? 'min-h-8' : 'h-8',
        'rounded-xl',
        'text-body-small',
        'font-semibold',
        'ring-offset-1',
        'focus-visible:ring-2',
        // Only have one icon at a time.
        // And if there's text, there'll only be a left or right icon.
        $slots.icon && 'h-8 w-8',
        !$slots.icon && 'px-3',
      ],
      sizePreset === OzContainedButtonSizePreset.H24px && [
        autoHeight ? 'min-h-6' : 'h-6',
        'rounded-lg',
        'text-body-extra-small',
        'font-semibold',
        'ring-offset-1',
        'focus-visible:ring-2',
        $slots.icon && 'h-6 w-6',
        !$slots.icon && 'px-3',
      ],

      sizePreset === OzContainedButtonSizePreset.H32pxW56px && [
        autoHeight ? 'min-h-8 min-w-14' : 'h-8 w-14',
        'rounded-2xl',
        'text-heading-4',
        'font-semibold',
        'focus-visible:ring-[2.5px]',

        // No ring offset when only icon. Offset is present otherwise.
        !$slots.icon && [
          // Needs to be on a new line for the JIT to work -_-
          'ring-offset-[1.5px]',
        ],

        // Expect at most one of the icon slots to be filled.
        $slots.icon && ['w-14'],
        $slots.leftIcon && !centerText && ['pe-5', 'ps-4', 'gap-2'],
        $slots.leftIcon && centerText && ['pe-11', 'ps-4', 'gap-2'], // pe-11 = ps-4 + 20px + gap-2
        $slots.rightIcon && !centerText && ['pe-2', 'ps-5', 'gap-1'],
        $slots.rightIcon && centerText && ['pe-2', 'ps-8', 'gap-1'], // ps-8 = pe-2 + 20px + gap-1
        !$slots.icon && !$slots.leftIcon && !$slots.rightIcon && ['px-5'],
      ],
      sizePreset === OzContainedButtonSizePreset.H40W124px && [
        autoHeight ? 'min-h-10' : 'h-10',
        'rounded-2xl',
        'text-heading-4',
        'font-semibold',
        'focus-visible:ring-[2.5px]',
        'w-31',

        // No ring offset when only icon. Offset is present otherwise.
        !$slots.icon && [
          // Needs to be on a new line for the JIT to work -_-
          'ring-offset-[1.5px]',
        ],

        // Expect at most one of the icon slots to be filled.
        $slots.icon && ['w-10'],
        $slots.leftIcon && !centerText && ['pe-5', 'ps-4', 'gap-2'],
        $slots.leftIcon && centerText && ['pe-11', 'ps-4', 'gap-2'], // pe-11 = ps-4 + 20px + gap-2
        $slots.rightIcon && !centerText && ['pe-2', 'ps-5', 'gap-1'],
        $slots.rightIcon && centerText && ['pe-2', 'ps-8', 'gap-1'], // ps-8 = pe-2 + 20px + gap-1
        !$slots.icon && !$slots.leftIcon && !$slots.rightIcon && ['px-5'],
      ],

      /**
       * COLOR SCHEMES
       */

      // Common
      colorScheme && [
        // Focus ring
        {
          'ring-grape-500': darkMode === false,
          'ring-canary-500': darkMode === true,
          'ring-grape-500 dark:ring-canary-500': darkMode === 'auto',
        },
        ringOffsetColorClasses || {
          // Tailwind uses box shadows for focus rings.
          // We'll have to deviate from TW paradigm if we want truly transparent ring-offset color.
          'ring-offset-white': darkMode === false,
          'ring-offset-grey-900': darkMode === true,
          'dark:ring-offset-grey-900 ring-offset-white': darkMode === 'auto',
        },
        // Disabled - Base
        {
          'aria-disabled:bg-button-disabled-light': darkMode === false,
          'aria-disabled:bg-button-disabled-dark': darkMode === true,
          'aria-disabled:bg-button-disabled-light dark:aria-disabled:bg-button-disabled-dark': darkMode === 'auto',
        },
        {
          'aria-disabled:text-dark-text-400': darkMode === false,
          'aria-disabled:text-light-text-400': darkMode === true,
          'aria-disabled:text-dark-text-400 dark:aria-disabled:text-light-text-400': darkMode === 'auto',
        },
        // Disabled - Hover
        {
          'hover-hover:aria-disabled:bg-button-disabled-light': darkMode === false,
          'hover-hover:aria-disabled:bg-button-disabled-dark': darkMode === true,
          'hover-hover:aria-disabled:bg-button-disabled-light hover-hover:dark:aria-disabled:bg-button-disabled-dark':
            darkMode === 'auto',
        },
        {
          'hover-hover:aria-disabled:text-dark-text-400': darkMode === false,
          'hover-hover:aria-disabled:text-light-text-400': darkMode === true,
          'hover-hover:aria-disabled:text-dark-text-400 hover-hover:dark:aria-disabled:text-light-text-400 ':
            darkMode === 'auto',
        },
        // Active
        colorScheme !== OzContainedButtonColorScheme.SecondaryLightHover &&
          colorScheme !== OzContainedButtonColorScheme.SecondaryClearDark && [
            {
              'hover-hover:active:bg-grape-500': darkMode === false,
              'hover-hover:active:bg-canary-500': darkMode === true,
              'hover-hover:active:bg-grape-500 hover-hover:dark:active:bg-canary-500': darkMode === 'auto',
            },
            {
              'hover-hover:active:text-light-text-100': darkMode === false,
              'hover-hover:active:text-dark-text-100': darkMode === true,
              'hover-hover:active:text-light-text-100 hover-hover:dark:active:text-dark-text-100': darkMode === 'auto',
            },
          ],
        colorScheme === OzContainedButtonColorScheme.SecondaryLightHover && [
          {
            'hover-hover:active:bg-grey-200': darkMode === false,
            'hover-hover:active:bg-grey-600': darkMode === true,
            'hover-hover:active:bg-grey-200 hover-hover:dark:active:bg-grey-600': darkMode === 'auto',
          },
          {
            'hover-hover:active:text-dark-text-200': darkMode === false,
            'hover-hover:active:text-light-text-200': darkMode === true,
            'hover-hover:active:text-dark-text-200 hover-hover:dark:active:text-light-text-200': darkMode === 'auto',
          },
        ],
        colorScheme === OzContainedButtonColorScheme.SecondaryClearDark && 'hover-hover:active:bg-transparent',
        colorScheme === OzContainedButtonColorScheme.Purple && [
          {
            'hover-hover:active:bg-grape-500': darkMode === false,
            'hover-hover:active:bg-canary-500': darkMode === true,
            'hover-hover:active:bg-grape-500 hover-hover:dark:active:bg-canary-500': darkMode === 'auto',
          },
          {
            'hover-hover:active:text-light-text-100': darkMode === false,
            'hover-hover:active:text-dark-text-100': darkMode === true,
            'hover-hover:active:text-light-text-100 hover-hover:dark:active:text-dark-text-100': darkMode === 'auto',
          },
        ],
      ],

      // Schemes
      colorScheme === OzContainedButtonColorScheme.Primary && [
        // Base
        'bg-padlet-pink',
        'text-light-text-100',
        // Hovered
        'hover-hover:hover:bg-padlet-pink-600',
      ],
      colorScheme === OzContainedButtonColorScheme.PrimaryPulsing && [
        // Base
        'bg-padlet-pink',
        'text-light-text-100',

        // Hovered
        'hover-hover:hover:animate-pulse-bg-color',
        'pulse-bg-from-padlet-pink-500',
        {
          'pulse-bg-to-grape-500': darkMode === false,
          'pulse-bg-to-canary-500': darkMode === true,
          'pulse-bg-to-grape-500 dark:pulse-bg-to-canary-500': darkMode === 'auto',
        },

        // Disabled - Hover
        'hover-hover:aria-disabled:hover:animate-none',
      ],
      colorScheme === OzContainedButtonColorScheme.Secondary && [
        // Base
        {
          'bg-grey-100': darkMode === false,
          'bg-grey-700': darkMode === true,
          'bg-grey-100 dark:bg-grey-700': darkMode === 'auto',
        },
        {
          'text-dark-text-200': darkMode === false,
          'text-light-text-200': darkMode === true,
          'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
        },
        // Hovered
        {
          'hover-hover:hover:bg-grey-200': darkMode === false,
          'hover-hover:hover:bg-grey-600': darkMode === true,
          'hover-hover:hover:bg-grey-200 hover-hover:dark:hover:bg-grey-600': darkMode === 'auto',
        },
      ],
      colorScheme === OzContainedButtonColorScheme.SecondaryActive && [
        // Base
        {
          'bg-grey-100': darkMode === false,
          'bg-grey-700': darkMode === true,
          'bg-grey-100 dark:bg-grey-700': darkMode === 'auto',
        },
        {
          'text-grape-500': darkMode === false,
          'text-canary-500': darkMode === true,
          'text-grape-500 dark:text-canary-500': darkMode === 'auto',
        },
        // Hovered
        {
          'hover-hover:hover:bg-grey-200': darkMode === false,
          'hover-hover:hover:bg-grey-600': darkMode === true,
          'hover-hover:hover:bg-grey-200 hover-hover:dark:hover:bg-grey-600': darkMode === 'auto',
        },
      ],
      colorScheme === OzContainedButtonColorScheme.SecondaryContrast && [
        // Base
        {
          'bg-grey-100': darkMode === false,
          'bg-grey-700': darkMode === true,
          'bg-grey-100 dark:bg-grey-700': darkMode === 'auto',
        },
        {
          'text-dark-text-100': darkMode === false,
          'text-light-text-100': darkMode === true,
          'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
        },
        // Hovered
        {
          'hover-hover:hover:bg-grey-200': darkMode === false,
          'hover-hover:hover:bg-grey-600': darkMode === true,
          'hover-hover:hover:bg-grey-200 hover-hover:dark:hover:bg-grey-600': darkMode === 'auto',
        },
      ],
      colorScheme === OzContainedButtonColorScheme.SecondaryDestructive && [
        // Disabled - Base
        'aria-disabled:text-danger-50',
        // Disabled - Hover
        'hover-hover:aria-disabled:text-danger-50',
        // Base
        {
          'bg-grey-100': darkMode === false,
          'bg-grey-700': darkMode === true,
          'bg-grey-100 dark:bg-grey-700': darkMode === 'auto',
        },
        'text-danger-100',
        // Hovered
        {
          'hover-hover:hover:bg-grey-200': darkMode === false,
          'hover-hover:hover:bg-grey-600': darkMode === true,
          'hover-hover:hover:bg-grey-200 hover-hover:dark:hover:bg-grey-600': darkMode === 'auto',
        },
      ],
      colorScheme === OzContainedButtonColorScheme.SecondaryClear && [
        // Base
        'bg-transparent',
        {
          'text-dark-text-200': darkMode === false,
          'text-light-text-200': darkMode === true,
          'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
        },
        // Hovered
        'hover-hover:hover:bg-opacity-10',
        {
          'hover-hover:hover:bg-overlay-text-bg-light-mode-light-hover': darkMode === false || darkMode === 'auto',
          'hover-hover:hover:bg-overlay-text-bg-dark-mode-dark-hover': darkMode === true,
          'hover-hover:dark:hover:bg-overlay-text-bg-dark-mode-dark-hover': darkMode === 'auto',
        },
      ],
      colorScheme === OzContainedButtonColorScheme.SecondaryClearDark && [
        // Disabled - Base
        'aria-disabled:bg-transparent',
        // Disabled - Hover
        'hover-hover:aria-disabled:bg-transparent',
        // Base
        'bg-transparent',
        {
          'text-dark-text-200': darkMode === false,
          'text-light-text-200': darkMode === true,
          'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
        },
        // Hovered
        'hover-hover:hover:bg-opacity-100',
        !disabled && {
          'hover-hover:hover:bg-light-text-300': darkMode === false || darkMode === 'auto',
          'hover-hover:hover:bg-dark-text-300': darkMode === true,
          'hover-hover:dark:hover:bg-dark-text-300': darkMode === 'auto',
        },
      ],
      colorScheme === OzContainedButtonColorScheme.SecondaryClearText && [
        // Base
        'bg-transparent',
        {
          'text-dark-text-200': darkMode === false,
          'text-light-text-200': darkMode === true,
          'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
        },
        // Hovered
        {
          'hover-hover:hover:text-dark-text-100': darkMode === false,
          'hover-hover:hover:text-light-text-100': darkMode === true,
          'hover-hover:hover:text-dark-text-100 hover-hover:dark:hover:text-light-text-100': darkMode === 'auto',
        },
      ],
      colorScheme === OzContainedButtonColorScheme.SecondaryClearActive && [
        // Base
        'bg-transparent',
        {
          'text-grape-500': darkMode === false,
          'text-canary-500': darkMode === true,
          'text-grape-500 dark:text-canary-500': darkMode === 'auto',
        },
        // Hovered
        'hover-hover:hover:bg-opacity-10',
        {
          'hover-hover:hover:bg-overlay-text-bg-light-mode-light-hover': darkMode === false || darkMode === 'auto',
          'hover-hover:hover:bg-overlay-text-bg-dark-mode-dark-hover': darkMode === true,
          'hover-hover:dark:hover:bg-overlay-text-bg-dark-mode-dark-hover': darkMode === 'auto',
        },
      ],
      colorScheme === OzContainedButtonColorScheme.Alternative && [
        // Base
        {
          'bg-grey-900': darkMode === false,
          'bg-grey-0': darkMode === true,
          'bg-grey-900 dark:bg-grey-0': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === false,
          'text-dark-text-100': darkMode === true,
          'text-light-text-100 dark:text-dark-text-100': darkMode === 'auto',
        },
        // Hovered
        {
          'hover-hover:hover:bg-grey-800': darkMode === false,
          'hover-hover:hover:bg-grey-100': darkMode === true,
          'hover-hover:hover:bg-grey-800 hover-hover:dark:hover:bg-grey-100': darkMode === 'auto',
        },
      ],
      colorScheme === OzContainedButtonColorScheme.AlternativeSecondary && [
        // Base
        'bg-grey-0',
        'text-dark-text-100',
        // Hovered
        {
          'hhover:bg-grey-50': darkMode === false,
          'hhover:bg-grey-100': darkMode === true,
          'hhover:bg-grey-50 dark:hhover:bg-grey-100': darkMode === 'auto',
        },
      ],
      colorScheme === OzContainedButtonColorScheme.AlternativeHeavy && [
        // Base
        {
          'bg-grey-900': darkMode === false,
          'bg-grey-0': darkMode === true,
          'bg-grey-900 dark:bg-grey-0': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === false,
          'text-dark-text-100': darkMode === true,
          'text-light-text-100 dark:text-dark-text-100': darkMode === 'auto',
        },
        // Hovered
        {
          'hover-hover:hover:bg-grey-800': darkMode === false,
          'hover-hover:hover:bg-grey-100': darkMode === true,
          'hover-hover:hover:bg-grey-800 hover-hover:dark:hover:bg-grey-100': darkMode === 'auto',
        },
      ],
      colorScheme === OzContainedButtonColorScheme.Transparent && [
        // Disabled - Base
        'aria-disabled:bg-transparent',
        // Disabled - Hover
        'hover-hover:aria-disabled:bg-transparent',
        // Base
        {
          'bg-transparent-button-light': darkMode === false,
          'bg-transparent-button-dark': darkMode === true,
          'bg-transparent-button-light dark:bg-transparent-button-dark': darkMode === 'auto',
        },
        {
          'text-dark-text-200': darkMode === false,
          'text-light-text-200': darkMode === true,
          'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
        },
        // Hovered
        {
          'hover-hover:hover:bg-transparent-button-hover-light': darkMode === false,
          'hover-hover:hover:bg-transparent-button-hover-dark': darkMode === true,
          'hover-hover:hover:bg-transparent-button-hover-light hover-hover:dark:hover:bg-transparent-button-hover-dark':
            darkMode === 'auto',
        },
      ],
      colorScheme === OzContainedButtonColorScheme.Orange && [
        // Base
        'bg-tangerine-500',
        'text-light-text-100',
        // Hovered
        'hover-hover:hover:bg-tangerine-600',
      ],
      colorScheme === OzContainedButtonColorScheme.Destructive && [
        // Base
        'bg-danger-100',
        'text-light-text-100',
        // Hovered
        'hover-hover:hover:bg-scarlet-600',
      ],
      colorScheme === OzContainedButtonColorScheme.DestructiveClear && [
        // Disabled - Base
        'aria-disabled:bg-transparent',
        'aria-disabled:text-danger-50',
        // Disabled - Hover
        'hover-hover:aria-disabled:bg-transparent',
        'hover-hover:aria-disabled:text-danger-50',
        // Base
        'bg-transparent',
        'text-danger-100',
        // Hovered
        {
          'hover-hover:hover:bg-scarlet-100': darkMode === false,
          'hover-hover:hover:bg-scarlet-1000': darkMode === true,
          'hover-hover:hover:bg-scarlet-100 hover-hover:dark:hover:bg-scarlet-1000': darkMode === 'auto',
        },
      ],
      colorScheme === OzContainedButtonColorScheme.Green && [
        // Base
        'bg-park-500',
        'text-light-text-100',
        // Hovered
        'hover-hover:hover:bg-park-600',
      ],
      colorScheme === OzContainedButtonColorScheme.Grey && [
        // Base
        {
          'bg-grey-50': darkMode === false,
          'bg-grey-800': darkMode === true,
          'bg-grey-50 dark:bg-grey-800': darkMode === 'auto',
        },
        {
          'text-dark-text-200': darkMode === false,
          'text-light-text-200': darkMode === true,
          'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
        },
        // Hovered
        {
          'hover-hover:hover:bg-grey-100': darkMode === false,
          'hover-hover:hover:bg-grey-700': darkMode === true,
          'hover-hover:hover:bg-grey-100 hover-hover:dark:hover:bg-grey-700': darkMode === 'auto',
        },
      ],
      colorScheme === OzContainedButtonColorScheme.Active && [
        // Base
        {
          'bg-grape-500': darkMode === false,
          'bg-canary-500': darkMode === true,
          'bg-grape-500 dark:bg-canary-500': darkMode === 'auto',
        },
        {
          'text-light-text-100': darkMode === false,
          'text-dark-text-100': darkMode === true,
          'text-light-text-100 dark:text-dark-text-100': darkMode === 'auto',
        },
      ],
      colorScheme === OzContainedButtonColorScheme.Purple && [
        // Base
        'bg-grape-500',
        'text-light-text-100',
        // Hovered
        'hover-hover:hover:bg-grape-600',
      ],
      colorScheme === OzContainedButtonColorScheme.Blue && [
        // Base
        'bg-oceanic-500',
        'text-light-text-100',
        // Hovered
        'hover-hover:hover:bg-oceanic-600',
      ],
      colorScheme === OzContainedButtonColorScheme.BlueClear && [
        // Base
        'bg-transparent',
        'text-oceanic-500',
        {
          'hover-hover:hover:bg-transparent-button-hover-light': darkMode === false,
          'hover-hover:hover:bg-transparent-button-hover-dark': darkMode === true,
          'hover-hover:hover:bg-transparent-button-hover-light hover-hover:dark:hover:bg-transparent-button-hover-dark':
            darkMode === 'auto',
        },
      ],
      colorScheme === OzContainedButtonColorScheme.GreenClear && [
        // Base
        'bg-transparent',
        'text-park-500',
        {
          'hover-hover:hover:bg-transparent-button-hover-light': darkMode === false,
          'hover-hover:hover:bg-transparent-button-hover-dark': darkMode === true,
          'hover-hover:hover:bg-transparent-button-hover-light hover-hover:dark:hover:bg-transparent-button-hover-dark':
            darkMode === 'auto',
        },
      ],
    ]"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
    @mousedown="$emit('mousedown', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
    @click="$emit('click', $event)"
    @pointerdown="$emit('pointerdown', $event)"
  >
    <!-- @slot Left icon. -->
    <slot name="leftIcon"></slot>

    <!-- @slot Text. -->
    <span v-if="text" :class="['flex-1', shouldTruncateText && 'truncate']">{{ text }}</span>

    <!-- @slot Right icon. -->
    <slot name="rightIcon"></slot>

    <!-- @slot Only icon. -->
    <slot name="icon"></slot>

    <!-- @slot For customization. -->
    <slot></slot>
  </OzBaseButton>
</template>
